export const tagsList = ['上場目標', '上場企業', '医療・ヘルスケア・美容', '少数精鋭', 'AI・人口知能・ビッグデータ', '旅行（インバウンド）', '圧倒的コンサル力',
  'エコビジネス', '最新技術', 'スタートアップ', '圧倒的マーケティング力', 'アプリ開発', '独自のサービス', '海外展開', 'ソーシャルビジネス', 'ブルーオーシャン',
  '圧倒的営業力', '学生起業社長', 'フィンテック', '自社メディア', '女性が活躍', '20代年収1,000万', '圧倒的クリエイティブ力', '動画・映像制作', '元アスリート',
  '働き方・教育を変える', '圧倒的技術力', '新規事業'];
export const oneSectionStatus = ['WANTED_EXIT', 'EVENT_FINISHED'];
export const towSectionStatus = ['REQUESTED', 'LOCKED', 'WANTED'];
export const EventManagementMenu = [
  {
    path: '/management-event/wanted',
    title: '募集中',
    icon: 'images/wanted.svg'
  },
  {
    path: '/management-event/locked',
    title: '開催決定',
    icon: 'images/locked.svg'
  },
  {
    path: '/management-event',
    title: '下書き',
    icon: 'images/draft.svg'
  },
  {
    path: '/management-event/finished',
    title: '終了',
    icon: 'images/finished.svg'
  },
  {
    path: '/management-event/canceled',
    title: 'キャンセル',
    icon: 'images/canceled.svg'
  }
];
export const StudentManagementMenu = [
  {
    path: '/management-student',
    title: 'ごちリクエスト管理',
    icon: 'images/student-management-follower.svg'
  },
  {
    path: '/management-student/students-of-event',
    title: 'イベントごとにみる',
    icon: 'images/list-of-student.svg'
  },
  {
    path: '/management-student/events-of-student',
    title: '学生ごとにみる',
    icon: 'images/list-of-event.svg'
  },
  {
    path: '/management-student/search',
    title: '学生を探す',
    icon: 'images/icon-search.svg',
    childrenPath: ['/management-student/search', '/management-student/offer'],
    children: [
      {
        path: '/management-student/search',
        title: 'オファーを送る',
        icon: 'images/icon-review-offer-sent.svg'
      },
      {
        path: '/management-student/offer',
        title: 'オファー管理',
        icon: 'images/icon-review-offer-management.svg'
      }
    ],
    isPC: true
  }, {
    path: '/management-student/search',
    title: 'オファーを送る',
    icon: 'images/icon-review-offer-sent.svg',
    isMobile: true
  },
  {
    path: '/management-student/offer',
    title: 'オファー管理',
    icon: 'images/icon-review-offer-management.svg',
    isMobile: true
  }
];
export const StudentManagementFilter = [{
  name: '参加確定',
  value: 'APPROVED',
  icon: 'images/student-management-joined.svg'
}, {
  name: '参加拒否',
  value: 'DENIED',
  icon: 'images/student-management-denied.svg'
}];
export const STUDENT_MANAGEMENT_FILTERS = {
  APPROVED: 'APPROVED',
  DENIED: 'DENIED'
};
export const STUDENT_MANAGEMENT_EVENT_SECTION_PERPAGE = 5;
export const STUDENT_MANAGEMENT_EVENT_PERPAGE = 15;
export const FOLLOWER_TYPE = {
  GOCHI: 'GOCHI',
  FOLLOW: 'FOLLOW'
};
// eslint-disable-next-line
export const containsURL = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const offerStatusMap = [
  {
    value: 'ACCEPTED_REQUEST',
    name: 'オファー承諾'
  },
  {
    value: 'EXAMINING_CANDIDATE',
    name: '選考中'
  },
  {
    value: 'REJECTED_CANDIDATE',
    name: '不探用'
  },
  {
    value: 'APPOINTED',
    name: '内定'
  },
  {
    value: 'WAITTING_FOR_ANSWER',
    name: 'オファーリクエスト'
  }
];

export const offerAvailableStatus = {
  ACCEPTED_REQUEST: [
    {
      value: 'ACCEPTED_REQUEST',
      name: 'オファー承諾'
    },
    {
      value: 'EXAMINING_CANDIDATE',
      name: '選考中'
    },
    {
      value: 'REJECTED_CANDIDATE',
      name: '不探用'
    },
    {
      value: 'APPOINTED',
      name: '内定'
    }
  ],
  EXAMINING_CANDIDATE: [
    {
      value: 'EXAMINING_CANDIDATE',
      name: '選考中'
    },
    {
      value: 'REJECTED_CANDIDATE',
      name: '不探用'
    },
    {
      value: 'APPOINTED',
      name: '内定'
    }
  ],
  REJECTED_CANDIDATE: [
    {
      value: 'REJECTED_CANDIDATE',
      name: '不探用'
    },
    {
      value: 'EXAMINING_CANDIDATE',
      name: '選考中'
    },
    {
      value: 'APPOINTED',
      name: '内定'
    }
  ],
  APPOINTED: [
    {
      value: 'APPOINTED',
      name: '内定'
    },
    {
      value: 'EXAMINING_CANDIDATE',
      name: '選考中'
    },
    {
      value: 'REJECTED_CANDIDATE',
      name: '不探用'
    }
  ],
  WAITTING_FOR_ANSWER: [
    {
      value: 'WAITTING_FOR_ANSWER',
      name: 'オファーリクエスト'
    }
  ]
};

export const OFFER_EVENT_STATUS = [
  {
    name: '保留中',
    value: 'WAITTING_FOR_ANSWER'
  },
  {
    name: '承諾',
    value: 'ACCEPTED_REQUEST'
  },
  {
    name: '辞退',
    value: 'REJECTED_REQUEST'
  }
];

export const OFFER_EVENT_STATUS_KEYS = {
  WAITTING_FOR_ANSWER: 'WAITTING_FOR_ANSWER',
  ACCEPTED_REQUEST: 'ACCEPTED_REQUEST',
  REJECTED_REQUEST: 'REJECTED_REQUEST',
  REJECTED_CANDIDATE: 'REJECTED_CANDIDATE',
  EXAMINING_CANDIDATE: 'EXAMINING_CANDIDATE',
  APPOINTED: 'APPOINTED'
};

export const NOTIFICATION_TYPE = {
  INVITE_JOIN_EVENT: 'INVITE_JOIN_EVENT'
};

export const NOTIFICATION_MESSAGE_BY_TYPE = {
  INVITE_JOIN_EVENT: 'イベント参加リクエストが届きました。'
};

export const ID_ALL_COLLEGES = 'u000000196';
export const ROLE = {
  PRESIDENT: 'PRESIDENT',
  STUDENT: 'STUDENT'
};

export const OFFER_FILTER_OPTIONS = [
  {
    value: 'ACCEPTED_REQUEST',
    name: 'オファー承諾'
  },
  {
    value: 'EXAMINING_CANDIDATE',
    name: '選考中'
  },
  {
    value: 'REJECTED_CANDIDATE',
    name: '不探用'
  },
  {
    value: 'APPOINTED',
    name: '内定'
  },
  {
    value: 'WAITTING_FOR_ANSWER',
    name: 'オファーリクエスト'
  }
];

export const GOCHI_TYPE = {
  NEW: 'NEW',
  ACCEPTED: 'ACCEPTED',
  EXAMINATION: 'EXAMINATION',
  REJECTED: 'REJECTED',
  CONFIRMED: 'CONFIRMED'
};

export const GOCHI_FILTER_OPTIONS = [
  {
    value: 'NEW',
    name: 'ごちリクエスト'
  },
  {
    value: 'ACCEPTED',
    name: '承諾'
  },
  {
    value: 'EXAMINATION',
    name: '選考中'
  },
  {
    value: 'REJECTED',
    name: '不採用'
  },
  {
    value: 'CONFIRMED',
    name: '内定'
  }
];

export const gochiAvailableStatus = {
  ACCEPTED: [
    {
      value: 'ACCEPTED',
      name: '承諾'
    },
    {
      value: 'EXAMINATION',
      name: '選考中'
    },
    {
      value: 'REJECTED',
      name: '不探用'
    },
    {
      value: 'CONFIRMED',
      name: '内定'
    }
  ],
  EXAMINATION: [
    {
      value: 'EXAMINATION',
      name: '選考中'
    },
    {
      value: 'REJECTED',
      name: '不探用'
    },
    {
      value: 'CONFIRMED',
      name: '内定'
    }
  ],
  REJECTED: [
    {
      value: 'EXAMINATION',
      name: '選考中'
    },
    {
      value: 'REJECTED',
      name: '不探用'
    },
    {
      value: 'CONFIRMED',
      name: '内定'
    }
  ],
  CONFIRMED: [
    {
      value: 'EXAMINATION',
      name: '選考中'
    },
    {
      value: 'REJECTED',
      name: '不探用'
    },
    {
      value: 'CONFIRMED',
      name: '内定'
    }
  ]
};

export const LEGAL_DRINKING_AGE = 20;
